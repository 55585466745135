import {remoteForm} from '@github/remote-form'
import {showGlobalError} from '../behaviors/ajax-error'

function updatePendingCommentsCount(menu: Element, pendingReviewCommentsCount: number) {
  const container = menu.closest<HTMLElement>('.js-review-state-classes')!
  const buttonText = document.querySelector<HTMLElement>('.js-review-changes')!

  container.classList.toggle('is-review-pending', pendingReviewCommentsCount > 0)

  for (const el of document.querySelectorAll('.js-pending-review-comment-count')) {
    el.textContent = String(pendingReviewCommentsCount)
  }

  for (const el of document.querySelectorAll('.js-pending-comment-count-type')) {
    if (el instanceof HTMLElement) {
      el.textContent = el.getAttribute(pendingReviewCommentsCount === 1 ? 'data-singular-string' : 'data-plural-string')
    }
  }

  if (pendingReviewCommentsCount > 0) {
    buttonText.textContent = buttonText.getAttribute('data-pending-message') || ''

    const button = menu.querySelector<HTMLElement>('.js-reviews-toggle')
    button?.classList.add('anim-pulse-in')
    button?.addEventListener('animationend', () => button.classList.remove('anim-pulse-in'), {once: true})
  } else {
    buttonText.textContent = buttonText.getAttribute('data-message') || ''
  }
}

function updatePendingHandler(pendingReviewCommentsCount: number) {
  const menu = document.querySelector('.js-reviews-container')
  if (menu) setTimeout(() => updatePendingCommentsCount(menu, pendingReviewCommentsCount))
}

remoteForm('.js-inline-comment-form', async function (form, send) {
  const response = await send.text()
  updatePendingHandler(response.json.pendingReviewCommentsCount)
})

remoteForm('.js-pending-review-comment .js-comment-delete', async function (form, send) {
  const response = await send.text()
  updatePendingHandler(response.json.pendingReviewCommentsCount)
})

// TODO Replace with data-replace-remote-form behavior.
remoteForm('.js-resolvable-timeline-thread-form', async function (form, send) {
  try {
    const response = await send.html()
    const container = form.closest<HTMLElement>('.js-resolvable-timeline-thread-container')!
    container.replaceWith(response.html)
  } catch {
    showGlobalError()
  }
})
